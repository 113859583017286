body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

footer {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  background-color: white;
  text-align: center;
}

@font-face {
  font-family: "RetroGaming";
  src: local("RetroGaming"), url("./fonts/RetroGaming.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "GameMusic";
  src: local("GameMusic"), url("./fonts/GameMusic.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Arcade";
  src: local("Arcade"), url("./fonts/Arcade.TTF") format("truetype");
  font-weight: bold;
}
